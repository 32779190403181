import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';

import routes, { Route } from './routes';

import { RootState } from 'redux/store';

import UserDropdown from './UserDropdown';
import AdminLinkPanel from './AdminLinkPanel';
import GroupsLinkPanel from './GroupsLinkPanel';

import useWindowSize from 'utils/hooks/useWindowSize';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { css, isEmptyObject } from 'utils/helper';

interface IHeader {}

const Header: FC<IHeader> = (): React.ReactElement => {
  const { logged, user: userStore } = useSelector((state: RootState) => state.userData);
  const [{ user }] = useCookies(['user']);
  const router = useRouter();

  const [ isUserPopupActive, setIsUserPopupActive ] = useState<boolean>(false);
  const [ selectedItem, setSelectedItem ] = React.useState<number | null>(null);
  const [ navigationLinks ] = useState<Route[]>(routes);
  const [ isAdmin, setIsAdmin ] = useState<boolean>(false);

  const userDropElement = useRef<HTMLUListElement>(null);
  const header = useRef<HTMLInputElement>(null);

  const { width } = useWindowSize();
  const isMobile = width !== 0 && width <= 767;

  useEffect(() => {
    setIsUserPopupActive(false);
  }, [router.pathname]);

  const changeSelectedItem = (index: number | null) => {
    setSelectedItem(index);
  };

  useEffect(() => {
    if (user || userStore) {
      if (user) {
        setIsAdmin(Boolean(user.isAdmin));
      }
      if (userStore) {
        setIsAdmin(Boolean(userStore.isAdmin));
      }
    }
    if (isEmptyObject(user) || logged) return;
  }, [user, logged]);

  useEffect(() => {
    css(document.body, { overflow: isMobile ? 'hidden' : 'visible' });
    if (document.body.offsetWidth <= 768) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [isMobile]);

  useOnClickOutside(header, () => {
    setIsUserPopupActive(false);
  });

  const navigationElements = useMemo(() => {
    return navigationLinks.map((item, index) => {
      if (item.group || item.admin) {
        if ((!logged && item.label === 'svg') || (item.admin && !isAdmin)) return null;

        return (
          <li
            className={`header__nav-item${globalThis.window?.location.pathname === item.link ? ' isActive' : ''}`}
            key={`${item.label}_${index}`}
          >
            {!item.admin ? (
              <GroupsLinkPanel
                changeSelectedItem={changeSelectedItem}
                index={index}
                label={item.label}
                isMobile={isMobile}
                isActive={index === selectedItem}
                groupLink={item.group}
                link={item.link}
                setIsUserPopupActive={() => setIsUserPopupActive(false)}
              />
            ) : (
              <AdminLinkPanel
                index={index}
                isActive={index === selectedItem}
                changeSelectedItem={changeSelectedItem}
                isMobile={isMobile}
              />
            )}
          </li>
        );
      }

      return (
        <li
          className={`header__nav-item${router.pathname === item.link ? ' isActive' : ''}`}
          key={`${item.label}_${index}`}
          onClick={() => setIsUserPopupActive(false)}
        >
          <Link href={item.link}>{item.label}</Link>
        </li>
      );
    });
  }, [navigationLinks, logged, user, userStore, router.pathname, isMobile, selectedItem]);

  const handleUserPopup = useCallback(() => {
    setIsUserPopupActive((prevState) => !prevState);
  }, []);

  return (
    <header className="header" ref={header}>
      <div className="header__body container">
        <Link className="header__logo" href="/">
          <Image width={118} height={23} src="/images/global/logo-dfa-light.svg" alt="Digital Freight Alliance" />
        </Link>

        <nav className={`header__nav${isMobile && isUserPopupActive ? ' isOpen' : ''}`}>
          <ul className="header__nav-list">{navigationElements}</ul>
          <ul className="header__nav-list" ref={userDropElement}>
            {logged ? (
              <>
                <li className={`header__nav-item header__nav-item-right`} onClick={handleUserPopup}>
                  <span className={`header__nav__user-icon ${isUserPopupActive ? 'active' : ''}`} />
                </li>
                <UserDropdown isUserPopupActive={isUserPopupActive} isMobile={isMobile} />
              </>
            ) : (
              <li className={`header__nav-item${router.pathname === '/auth/sign-in' ? ' mobileActiveLink' : ''}`}>
                <Link href="/auth/sign-in">Sign In</Link>
              </li>
            )}
          </ul>
        </nav>

        {isMobile && (
          <div className="burger__wrapper" onClick={() => setIsUserPopupActive(!isUserPopupActive)}>
            <div className={`burger${isUserPopupActive ? ' isOpen' : ''}`} />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
